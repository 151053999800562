import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthState } from '../context/AuthContext';

const PrivateRoute = () => {
  const { user, loading } = useAuthState();

  if (loading) {
    return <div>Loading...</div>; // Sie können dies durch ein besseres Ladesymbol ersetzen
  }

  return user ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;