import React, { useState, useEffect } from 'react';
import { useAuthState } from '../../context/AuthContext';
import { storage } from '../../utils/firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, updateProfile, updateEmail, updatePassword, deleteUser } from 'firebase/auth';

const Profile = () => {
  const { user } = useAuthState();
  const [profilePicURL, setProfilePicURL] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [uploading, setUploading] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    if (user) {
      console.log("User found:", user);
      setDisplayName(user.displayName || '');
      setEmail(user.email || '');
      setProfilePicURL(user.photoURL || '');
    }
  }, [user]);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file && user) {
      const storageRef = ref(storage, `profileImages/${user.uid}`);
      setUploading(true);
      try {
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        console.log("File uploaded. URL:", url);
        setProfilePicURL(url);
        await updateProfile(auth.currentUser, { photoURL: url });
        console.log("Profile updated successfully with photoURL:", url);
        setUploading(false);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploading(false);
      }
    }
  };

  const handleSave = async () => {
    if (user) {
      try {
        await updateProfile(auth.currentUser, {
          displayName: displayName,
          photoURL: profilePicURL,
        });
        if (email !== user.email) {
          await updateEmail(auth.currentUser, email);
        }
        if (newPassword) {
          await updatePassword(auth.currentUser, newPassword);
        }
        alert("Profile updated successfully!");
        console.log("Profile updated successfully with displayName, email, and photoURL:", displayName, email, profilePicURL);
      } catch (error) {
        alert("Error updating profile: " + error.message);
        console.error("Error updating profile:", error);
      }
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        await deleteUser(auth.currentUser);
        alert("Account deleted successfully!");
      } catch (error) {
        alert("Error deleting account: " + error.message);
        console.error("Error deleting account:", error);
      }
    }
  };

  return (
    <div className="profile-container">
      <h1 className="text-center mb-4">Profile</h1>
      {profilePicURL && (
        <div className="text-center mb-4">
          <img src={profilePicURL} alt="Profile" className="profile-image" />
        </div>
      )}
      <div className="mb-3">
        <label className="form-label">Name:</label>
        <input
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <label className="form-label">New Password:</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="form-control"
          placeholder="Enter new password"
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Profile Picture:</label>
        <input type="file" onChange={handleUpload} className="form-control" />
        {uploading && <p>Uploading...</p>}
      </div>
      <div className="d-grid gap-2">
        <button onClick={handleSave} className="btn btn-primary">Save</button>
      </div>
      <div className="d-grid gap-2 mt-3">
        <button onClick={handleDeleteAccount} className="btn btn-danger">Delete Account</button>
      </div>
    </div>
  );
};

export default Profile;