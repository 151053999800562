import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState, useAuthDispatch } from '../../context/AuthContext';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

const Navbar = () => {
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const handleNavLinkClick = () => {
      const navCollapse = document.querySelector('.navbar-collapse');
      if (navCollapse && navCollapse.classList.contains('show')) {
        navCollapse.classList.remove('show');
      }
    };

    const navLinks = document.querySelectorAll('.nav-link');
    navLinks.forEach((link) => {
      link.addEventListener('click', handleNavLinkClick);
    });

    const dropdownLinks = document.querySelectorAll('.dropdown-item');
    dropdownLinks.forEach((link) => {
      link.addEventListener('click', handleNavLinkClick);
    });

    const navbarToggler = document.querySelector('.navbar-toggler');
    if (navbarToggler) {
      navbarToggler.addEventListener('click', () => {
        const navCollapse = document.querySelector('.navbar-collapse');
        if (navCollapse) {
          navCollapse.classList.toggle('show');
        }
      });
    }

    const handleOutsideClick = (event) => {
      const navCollapse = document.querySelector('.navbar-collapse');
      const navbarToggler = document.querySelector('.navbar-toggler');
      if (
        navCollapse &&
        navCollapse.classList.contains('show') &&
        !navCollapse.contains(event.target) &&
        !navbarToggler.contains(event.target)
      ) {
        navCollapse.classList.remove('show');
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener('click', handleNavLinkClick);
      });
      dropdownLinks.forEach((link) => {
        link.removeEventListener('click', handleNavLinkClick);
      });
      if (navbarToggler) {
        navbarToggler.removeEventListener('click', () => {
          const navCollapse = document.querySelector('.navbar-collapse');
          if (navCollapse) {
            navCollapse.classList.toggle('show');
          }
        });
      }
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container">
        <Link className="navbar-brand fw-bold" to="/">GS Unity</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
            <li className="nav-item"><a className="nav-link" href="/#services">Services</a></li>
            <li className="nav-item"><a className="nav-link" href="/#features">Features</a></li>
            <li className="nav-item"><a className="nav-link" href="/#about">About</a></li>
            <li className="nav-item"><a className="nav-link" href="/#testimonials">Testimonials</a></li>
            <li className="nav-item"><a className="nav-link" href="/#contact">Contact</a></li>
            {user ? (
              <li className="nav-item dropdown">
                <button className="nav-link dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src={user.photoURL || 'default-profile-pic-url'} alt="Profile" className="rounded-circle" style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }} />
                  {user.displayName}
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><button className="dropdown-item" onClick={handleLogout}>Logout</button></li>
                </ul>
              </li>
            ) : (
              <li className="nav-item"><Link className="nav-link" to="/login">Login</Link></li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;