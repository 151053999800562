import React from 'react';

const Footer = () => {
  return (
    <footer className="footer bg-light text-dark py-4">
      <div className="container text-center">
        <p className="mb-0">Design and Developed by <a href="https://gsproductions.ch/" target="_blank" rel="noopener noreferrer" className="text-primary">GS Productions</a></p>
      </div>
    </footer>
  );
};

export default Footer;