import React from 'react';

const Home = () => {
  return (
    <div>
      <section id="home" className="hero bg-light text-align py-5">
        <div className="container text-center" style={{ marginTop: '80px' }}>
          <h1 className="fw-bold mb-3" data-aos="fade-up">Welcome to GS Unity</h1>
          <p className="fs-4 mb-4" data-aos="fade-up" data-aos-delay="200">Empowering your business with innovative solutions.</p>
          <button onClick={() => document.getElementById('services').scrollIntoView({ behavior: 'smooth' })} className="btn btn-primary btn-lg scroll-to" data-aos="fade-up" data-aos-delay="400">Explore Services</button>
        </div>
      </section>

      <section id="services" className="py-5">
        <div className="container text-center">
          <h2 className="section-title" data-aos="fade-up">Services</h2>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <i className="fas fa-cog fa-3x text-primary mb-3"></i>
                  <h5 className="card-title fw-semibold">Service One</h5>
                  <p className="card-text">Description of service one.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <i className="fas fa-chart-line fa-3x text-primary mb-3"></i>
                  <h5 className="card-title fw-semibold">Service Two</h5>
                  <p className="card-text">Description of service two.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <i className="fas fa-users fa-3x text-primary mb-3"></i>
                  <h5 className="card-title fw-semibold">Service Three</h5>
                  <p className="card-text">Description of service three.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-light py-5">
        <div className="container text-center">
          <h2 className="section-title" data-aos="fade-up">Features</h2>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <i className="fas fa-bolt fa-3x text-primary mb-3"></i>
                  <h5 className="card-title fw-semibold">Feature One</h5>
                  <p className="card-text">Description of feature one.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <i className="fas fa-lock fa-3x text-primary mb-3"></i>
                  <h5 className="card-title fw-semibold">Feature Two</h5>
                  <p className="card-text">Description of feature two.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <i className="fas fa-heart fa-3x text-primary mb-3"></i>
                  <h5 className="card-title fw-semibold">Feature Three</h5>
                  <p className="card-text">Description of feature three.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="py-5">
        <div className="container text-center">
          <h2 className="section-title" data-aos="fade-up">About Us</h2>
          <p className="text-center" data-aos="fade-up" data-aos-delay="100">Information about GS Unity. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta.</p>
        </div>
      </section>

      <section id="testimonials" className="bg-light py-5">
        <div className="container text-center">
          <h2 className="section-title" data-aos="fade-up">Testimonials</h2>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <p className="card-text">"Testimonial one."</p>
                  <h5 className="card-title fw-semibold">- Customer One</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <p className="card-text">"Testimonial two."</p>
                  <h5 className="card-title fw-semibold">- Customer Two</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <p className="card-text">"Testimonial three."</p>
                  <h5 className="card-title fw-semibold">- Customer Three</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="py-5">
        <div className="container text-center">
          <h2 className="section-title" data-aos="fade-up">Contact Us</h2>
          <div className="row justify-content-center">
            <div className="col-lg-6 mb-4" data-aos="fade-up" data-aos-delay="100">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h5 className="card-title fw-semibold">Get In Touch</h5>
                  <p className="card-text">Fill out the form below to send us a message.</p>
                  <form id="contactForm" action="https://formspree.io/f/meqywnrq" method="POST">
                    <div className="mb-3">
                      <label htmlFor="contactName" className="form-label">Name</label>
                      <input type="text" className="form-control" id="contactName" name="name" required />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="contactEmail" className="form-label">Email</label>
                      <input type="email" className="form-control" id="contactEmail" name="email" required />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="contactMessage" className="form-label">Message</label>
                      <textarea className="form-control" id="contactMessage" name="message" rows="4" required></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">Send Message</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h5 className="card-title fw-semibold">Our Office</h5>
                  <p className="card-text">
                    <strong>Address:</strong> Standardstrasse 12, 1234 Stadt, GR Switzerland<br />
                    <strong>Email:</strong> info@gsunity.com<br />
                    <strong>Phone:</strong> (+41) 079 079 79 79
                  </p>
                  <div className="mt-4">
                    <h6 className="fw-bold">Follow Us</h6>
                    <a href="https://facebook.com" className="text-primary me-3"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://twitter.com" className="text-primary me-3"><i className="fab fa-twitter"></i></a>
                    <a href="https://linkedin.com" className="text-primary me-3"><i className="fab fa-linkedin-in"></i></a>
                    <a href="https://instagram.com" className="text-primary"><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;